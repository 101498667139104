import { gql } from '@apollo/client';
import { IsB2BLogged } from 'B2B/Utils/IsB2B';

export const REMOVE_FROM_WISHLIST = gql`
  mutation ($wishlistID: ID!, $id: [ID!]!) {
    removeProductsFromWishlist(wishlistId: $wishlistID, wishlistItemsIds: $id) {
      wishlist {
        id
        items_count
      }
    }
  }
`;
export const MOVE_WISHLIST_TO_CART = gql`
  mutation ($items: [ID!], $wishlistID: ID!) {
    addWishlistItemsToCart(wishlistId: $wishlistID, wishlistItemIds: $items) {
      status
      add_wishlist_items_to_cart_user_errors {
        code
        message
      }
      wishlist {
        id
        items_count
        items_v2 {
          items {
            id
            product {
              uid
              sku
              name
              brand_label
            }
          }
        }
      }
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query GetFilteredProducts(
    $C: Int!
    $F: ProductAttributeFilterInput
    $P: Int
    $S: ProductAttributeSortInput
  ) {
    products(filter: $F, currentPage: $C, pageSize: $P, sort: $S) {
      total_count
      sort_fields {
        default
        options {
          label
          value
          parameter
        }
      }
      aggregations {
        attribute_code
        searchable_in_filter_option
        label
        options {
          status
          label
          value
          count
          parent
          child
          display_in_category_filter
          swatch_data {
            type
            value
          }
        }
      }
      items {
        categories {
          name
        }
        global_brand
        child_details {
          attribute_uid
          parent_sku
        }
        uid
        labels
        name
        sku
        gst_tax
        only_x_left_in_stock
        stock_status
        special_price
        best_seller
        free_product_available
        rating_summary
        review_count
        offer_rules {
          discount
          type
          name
          show_on_product
        }
        brand_label
        thumbnail {
          sizes {
            image_id
            url
            width
            height
          }
          label
          url
        }
        ... on ConfigurableProduct {
          configurable_options {
            label
            attribute_code
            values {
              label
              value_index
              swatch_data {
                value
                __typename
              }
            }
          }
          variants {
            product {
              brand_label
              name
              sku
              uid
              free_product_available
              rating_summary
              review_count
              offer_rules {
                discount
                type
                name
                show_on_product
              }
              only_x_left_in_stock
              stock_status
              special_price
              price_range {
                minimum_price {
                  discount {
                    percent_off
                    __typename
                  }
                  regular_price {
                    value
                    currency
                    __typename
                  }
                  final_price {
                    value
                    currency
                    __typename
                  }
                  __typename
                }
                __typename
              }
              swatch_image
              __typename
            }
            attributes {
              uid
              label
              code
              value_index
              __typename
            }
            __typename
          }
        }
        ${
          IsB2BLogged()
            ? `
          price_tiers {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            quantity
          }`
            : ``
        }
        price_range {
          minimum_price {
            regular_price {
              ...MoneyFields
            }
            discount {
              percent_off
            }
            final_price {
              ...MoneyFields
            }
          }
          maximum_price {
            regular_price {
              ...MoneyFields
            }
            discount {
              percent_off
            }
            final_price {
              ...MoneyFields
            }
          }
        }
        __typename
      }
      total_count
      page_info {
        page_size
        current_page
        total_pages
      }
      __typename
    }
  }
  fragment MoneyFields on Money {
    value
    currency
  }
`;

export const FETCH_PRODUCTS_SKU = gql`
  query ($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      items {
        sku
        url_key
      }
    }
  }
`;

export const PRODUCT_AGGREGATIONS = gql`
  query GetFilteredAggregation($F: ProductAttributeFilterInput) {
    products(filter: $F) {
      total_count
      aggregations {
        attribute_code
        label
        options {
          label
          value
        }
      }
    }
  }
`;

export const FETCH_PRODUCT_SEARCH_QUERY = gql`
  query productSearch(
    $phrase: String!
    $currentPage: Int!
    $pageSize: Int!
    $sort: [SortOptions]
    $filter: [FitlerOptions!]
  ) {
    productSearch(
      phrase: $phrase
      currentPage: $currentPage
      pageSize: $pageSize
      sort: $sort
      filter: $filter
    ) {
      total_count
      page_info {
        current_page
        page_size
        total_pages
      }
      sort_fields {
        default
        options {
          label
          value
          parameter
        }
      }
      aggregations {
        attribute_code
        searchable_in_filter_option
        label
        options {
          title
          status
          label
          value
          count
          parent
          child
          display_in_category_filter
          swatch_data {
            type
            value
          }
        }
      }
      items {
        global_brand
        child_details {
          attribute_uid
          parent_sku
        }
        uid
        labels
        name
        sku
        gst_tax
        only_x_left_in_stock
        stock_status
        special_price
        best_seller
        free_product_available
        rating_summary
        review_count
        offer_rules {
          discount
          type
          name
          show_on_product
        }
        brand_label
        categories {
          name
        }
        thumbnail {
          sizes {
            image_id
            url
            width
            height
          }
          label
          url
        }
        ${
          IsB2BLogged
            ? `
          price_tiers {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            quantity
          }`
            : ``
        }
        ... on ConfigurableProduct {
          configurable_options {
            label
            attribute_code
            values {
              label
              value_index
              swatch_data {
                value
                __typename
              }
            }
          }
          variants {
            product {
              brand_label
              name
              sku
              uid
              free_product_available
              rating_summary
              review_count
              offer_rules {
                discount
                type
                name
                show_on_product
              }
              only_x_left_in_stock
              stock_status
              special_price
              price_range {
                minimum_price {
                  discount {
                    percent_off
                    __typename
                  }
                  regular_price {
                    value
                    currency
                    __typename
                  }
                  final_price {
                    value
                    currency
                    __typename
                  }
                  __typename
                }
                __typename
              }
              swatch_image
              __typename
            }
            attributes {
              uid
              label
              code
              value_index
              __typename
            }
            __typename
          }
        }
        price_range {
          minimum_price {
            regular_price {
              ...MoneyFields
            }
            discount {
              percent_off
            }
            final_price {
              ...MoneyFields
            }
          }
          maximum_price {
            regular_price {
              ...MoneyFields
            }
            discount {
              percent_off
            }
            final_price {
              ...MoneyFields
            }
          }
        }
        __typename
      }
      suggestions
      total_count
    }
  }
  fragment MoneyFields on Money {
    value
    currency
  }
`;

export const PRODUCT_DETAILS_QUERY = gql`
  query productquery($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        global_brand
        child_details {
                attribute_uid
                parent_sku
              }
        categories {
          name
        }
        size_volume_simple_product {
          label
          code
        }
        size_weight_simple_product {
          label
          code
        }
        swatch_data {
          label
          attribute_code
          type
          value
        }
        related_products {
          sku
          name
          image {
            url
            label
            __typename
          }
          __typename
        }
        uid
        product_quatity
        about_the_brand
        free_product_available
        rating_summary
        review_count
        offer_rules {
          discount
          type
          name
          show_on_product
          description
        }
        brand_label
        brands {
          entity_id
          is_monetized
          url_key
        }
        name
        sku
        only_x_left_in_stock
        product_quatity
        ${
          IsB2BLogged()
            ? `
          gst_tax
          price_tiers {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            quantity
          }`
            : ``
        }
        stock_status
        brand_name
        image {
          url
          label
        }
        thumbnail {
          url
          label
        }
        swatch_image
        media_gallery {
          url
          label
          ... on ProductImage {
            label
            role
            sizes {
              image_id
              url
              width
              height
            }
          }
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
            }
          }
          __typename
        }
        short_description {
          html
        }
        description {
          html
        }
        country_of_manufacture
        manufacturer_details
        product_benefit
        side_effects_safety_warning_ca
        formulation
        finish
        preference
        stay
        spf
        coverage
        is_it_heat_sensitive
        shelf_life_in_months
        skin_type
        skin_concern
        skin_tone
        hair_type
        hair_concern
        fragrance_family
        top_note_in_fragrance
        middle_note_in_fragrance
        bottom_note_in_fragrance
        warranty
        special_price
        key_ingredients
        list_of_all_ingredients
        manufacturer_name
        marketer_details
        packer_name
        packer_address
        importer_name
        importer_address
        direction_for_use
        size_volume_simple_product {
          label
          code
        }
        size_weight_simple_product {
          label
          code
        }
        swatch_data {
          label
          attribute_code
          type
          value
        }
        price_range {
          minimum_price {
            discount {
              percent_off
              __typename
            }
            regular_price {
              value
              currency
              __typename
            }
            final_price {
              value
              currency
              __typename
            }
            __typename
          }
          __typename
        }
        ... on ConfigurableProduct {
          configurable_options {
            label
            attribute_code
            values {
              value_index
              label
              swatch_data {
                value
                __typename
              }
              __typename
            }
            __typename
          }
          variants {
            product {

              brand_label
              ${
                IsB2BLogged()
                  ? `
                gst_tax
                price_tiers {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                  quantity
                }`
                  : ``
              }
              only_x_left_in_stock
              name
              sku
              uid
              free_product_available
              rating_summary
              review_count
              product_quatity
              offer_rules {
                discount
                type
                name
                show_on_product
                description
              }
              only_x_left_in_stock
              stock_status
              short_description {
                html
                __typename
              }
              description {
                html
                __typename
              }
              country_of_manufacture
              manufacturer_details
              product_benefit
              side_effects_safety_warning_ca
              formulation
              finish
              preference
              stay
              spf
              coverage
              is_it_heat_sensitive
              shelf_life_in_months
              skin_type
              skin_concern
              skin_tone
              hair_type
              hair_concern
              fragrance_family
              top_note_in_fragrance
              middle_note_in_fragrance
              bottom_note_in_fragrance
              warranty
              special_price
              key_ingredients
              list_of_all_ingredients
              manufacturer_name
              marketer_details
              packer_name
              packer_address
              importer_name
              importer_address
              direction_for_use
              price_range {
                minimum_price {
                  discount {
                    percent_off
                    __typename
                  }
                  regular_price {
                    value
                    currency
                    __typename
                  }
                  final_price {
                    value
                    currency
                    __typename
                  }
                  __typename
                }
                __typename
              }
              image {
                label
                url
                __typename
              }
              small_image {
                label
                url
                __typename
              }
              media_gallery {
                url
                label
                ... on ProductImage {
                  label
                  role
                  sizes {
                    image_id
                    url
                    width
                    height
                  }
                }
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    __typename
                  }
                  __typename
                }
                __typename
              }
              swatch_image
              __typename
            }
            attributes {
              uid
              label
              code
              value_index
              __typename
            }
            __typename
          }
          __typename
        }
      }
      __typename
    }
  }
`;

export const TINY_URL = gql`
  query ($url: String!) {
    tinyUrl(url: $url)
  }
`;

export const GET_PRODUCT_REVIEWS = gql`
  query productquery($sku: String!, $page: Int, $curr: Int) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        review_count
        rating_summary
        reviews(pageSize: $page, currentPage: $curr) {
          page_info {
            current_page
            page_size
            total_pages
          }
          items {
            average_rating
            created_at
            nickname
            ratings_breakdown {
              name
              value
            }
            summary
            text
          }
        }
        ... on ConfigurableProduct {
          variants {
            product {
              review_count
              rating_summary
              reviews(pageSize: $page, currentPage: $curr) {
                page_info {
                  current_page
                  page_size
                  total_pages
                }
                items {
                  average_rating
                  created_at
                  nickname
                  ratings_breakdown {
                    name
                    value
                  }
                  summary
                  text
                }
              }
            }
            attributes {
              uid
              label
              code
              value_index
              __typename
            }
          }
        }
      }
    }
  }
`;
