/* eslint-disable import/no-cycle */
import { useEffect, useRef, useState } from 'react';
import gstCertifcateImg from 'B2B/Assets/Images/gstCertificateImg.svg';
import fileIcon from 'B2B/Assets/Icons/fileIcon.svg';
import removeUploadedFile from 'B2B/Assets/Icons/removeUploadFile.svg';
import { useMutation } from '@apollo/client';
import { ADD_GST_DETAILS, CREATE_COMPANY, VALIDATE_PAN } from 'B2B/Gql';
import { useDispatch, useSelector } from 'react-redux';
import { getBase64 } from 'B2B/Utils/GetBase64';
import { GENERATE_CUSTOMER_TOKEN_MOBILE } from 'Gql/query';
import './GSTCertificate.css';
import {
  setEntityName,
  setPanCardFile,
  setPanCardNumber,
  setPanDateOfIssue,
  setUserType,
} from 'B2B/Redux/SignInSignUp/SignIn';
import { pushToDataLayer } from 'Utils/pushToDataLayer';

function GSTCertificate({ updateFormType, hasLoggedIn }) {
  const dispatch = useDispatch();

  const [createCompanyMutation] = useMutation(CREATE_COMPANY);
  const [addGstDetails] = useMutation(ADD_GST_DETAILS);
  const [GenerateCustomerTokenMobile] = useMutation(GENERATE_CUSTOMER_TOKEN_MOBILE);
  const [validatePANQuery] = useMutation(VALIDATE_PAN, {
    fetchPolicy: 'no-cache',
  });

  const b2bSignInState = useSelector((state) => state.b2bSignIn);

  const [uploadedFile, setUploadedFile] = useState();
  const [uploadedFileError, setUploadedFileError] = useState(false);
  const [isPanValidationCheck, setIsPanValidationCheck] = useState(null);
  const fileInputRef = useRef();
  const uploadedFileSizeLimitInBytes = 1024 * 1024;
  const allowedFileTypes = ['image/png', 'application/pdf', 'image/jpeg', 'image/jpg'];

  const fileUploadingHandler = () => {
    fileInputRef.current.click();
  };

  const fileDragOverHandler = (event) => {
    event.preventDefault();
  };

  const fileOnDropHandler = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setUploadedFile(file);
  };

  const fileUploadedHandler = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const removeUploadedFileHandler = () => {
    setUploadedFile(null);
  };

  const openFileInNewTab = () => {
    if (uploadedFile) {
      const fileUrl = URL.createObjectURL(uploadedFile);
      window.open(fileUrl, '_blank');
    }
  };

  useEffect(() => {
    const panValidateHandler = async () => {
      validatePANQuery({
        variables: {
          name: uploadedFile?.name,
          PAN: await getBase64(uploadedFile),
        },
      })
        .then((res) => {
          if (res?.errors) {
            setUploadedFileError(res?.errors?.[0]?.message);
            setIsPanValidationCheck(false);
          } else {
            dispatch(setPanCardNumber(res?.data?.validatePAN?.pan_no));
            dispatch(setUserType(res?.data?.validatePAN?.pan_type));
            dispatch(setEntityName(res?.data?.validatePAN?.pan_name));
            dispatch(setPanCardFile(res?.data?.validatePAN.pan_link));
            dispatch(setPanDateOfIssue(res?.data?.pan_date_of_issue));
            setUploadedFileError();
            setIsPanValidationCheck(true);
          }
        })
        .catch((err) => {
          setIsPanValidationCheck(false);
          setUploadedFileError(err?.message);
        });
    };

    if (uploadedFile) {
      if (!allowedFileTypes.includes(uploadedFile.type)) {
        setUploadedFileError('Please upload a valid file format.');
        setIsPanValidationCheck(false);
      } else if (uploadedFileSizeLimitInBytes < uploadedFile?.size) {
        setUploadedFileError('File size exceeds limit');
        setIsPanValidationCheck(false);
      } else {
        setUploadedFileError();
        if (b2bSignInState?.businessType === 'pan_card') {
          setIsPanValidationCheck(null);
          panValidateHandler();
        }
      }
    } else {
      setUploadedFileError();
      setIsPanValidationCheck(null);
    }
  }, [uploadedFile]);

  const onSubmitHandler = async () => {
    try {
      if (b2bSignInState?.businessType === 'pan_card') {
        updateFormType('GSTVerification');
      } else if (hasLoggedIn) {
        const addGstDetailsResponse = await addGstDetails({
          variables: {
            gst: b2bSignInState?.gstin,
            name: b2bSignInState?.entityName,
            address: b2bSignInState?.principalAddress,
            s1: b2bSignInState?.address?.street1,
            s2: b2bSignInState?.address?.street2,
            city: b2bSignInState?.address?.city,
            state: b2bSignInState?.address?.state,
            pincode: b2bSignInState?.address?.pincode,
            country: b2bSignInState?.address?.country,
            stateCode: b2bSignInState?.address?.state_code,
            fname: uploadedFile?.name,
            file: await getBase64(uploadedFile),
            entity: b2bSignInState?.entityType,
          },
        });

        if (addGstDetailsResponse?.data?.addGstdetails.message) {
          window.location.href = '/profile/GST-input-credit';
        } else {
          setUploadedFileError(addGstDetailsResponse?.errors?.[0]?.message);
        }
      } else {
        const createCompanyResponse = await createCompanyMutation({
          variables: {
            company_name: b2bSignInState?.entityName,
            firstname: b2bSignInState?.entityName?.split(' ')?.[0]?.replace(/[^a-zA-Z0-9]/g, ''),
            lastname:
              b2bSignInState?.entityName
                ?.split(' ')
                ?.slice(1)
                ?.join(' ')
                ?.replace(/[^a-zA-Z0-9 ]/g, '') || ' ',
            company_email: b2bSignInState?.email,
            mobilenumber: `91${b2bSignInState?.mobileNumber}`,
            gstin: b2bSignInState?.gstin,
            fileName: uploadedFile?.name,
            base64_encoded_file: await getBase64(uploadedFile),
            password: 'password',
            business_details_type: b2bSignInState?.businessType,
            street:
              `${b2bSignInState?.address?.street1} ${b2bSignInState?.address?.street2}` === ' '
                ? '0'
                : `${b2bSignInState?.address?.street1} ${b2bSignInState?.address?.street2}`,
            city: b2bSignInState?.address?.city,
            pincode: b2bSignInState?.address?.pincode,
            region_code: b2bSignInState?.address?.state_code,
            country_id: b2bSignInState?.address?.country,
            business_address: b2bSignInState?.principalAddress,
            customer_business_entity: b2bSignInState?.entityType,
          },
        });

        if (createCompanyResponse?.data?.createCompany) {
          window.dataLayer.push({ ecommerce: null });
          pushToDataLayer({
            event: 'Register',
            ecommerce: {
              mobile: `91${b2bSignInState?.mobileNumber}`,
              first_name: b2bSignInState?.entityName?.split(' ')?.[0],
              last_name: b2bSignInState?.entityName?.split(' ')?.slice(1).join(' '),
              email: b2bSignInState?.email,
            },
          });
          const generateCustomerTokenMobileResponse = await GenerateCustomerTokenMobile({
            variables: {
              mobile: `91${b2bSignInState?.mobileNumber}`,
              password: 'password',
            },
          });
          if (generateCustomerTokenMobileResponse?.data?.generateCustomerTokenMobile) {
            localStorage.setItem(
              'token',
              generateCustomerTokenMobileResponse?.data?.generateCustomerTokenMobile?.token,
            );
            localStorage.setItem('isB2B', true);
            localStorage.setItem('loggedPopUpB2B', true);
            window.location.href = '/business';
          } else {
            setUploadedFileError(generateCustomerTokenMobileResponse?.errors?.[0]?.message);
          }
        } else {
          setUploadedFileError(createCompanyResponse?.errors?.[0]?.message);
        }
      }
    } catch (err) {
      setUploadedFileError('An unexpected error occurred.');
    }
  };

  return (
    <div className='gstCertificate'>
      {uploadedFile && (
        <div className='gstCertifcate-uploaded'>
          <img src={fileIcon} alt='fileIcon' />
          <div className='gstCertificate-fileInfo'>
            <div className='gstCertificate-fileDetails'>
              <div className='gstCertificate-fileName'>{uploadedFile?.name}</div>
              <div className='gstCertificate-fileOptions'>
                <div
                  className='gstCertificate-fileView'
                  onClick={() => openFileInNewTab()}
                  tabIndex={0}
                  role='button'
                >
                  view
                </div>
                <img
                  className='gstCertificate-fileVRemove'
                  src={removeUploadedFile}
                  onClick={() => {
                    removeUploadedFileHandler();
                  }}
                  tabIndex={0}
                  role='button'
                  alt='removeUploadedFile'
                />
              </div>
            </div>
            {b2bSignInState?.businessType === 'pan_card' ? (
              <>
                {isPanValidationCheck !== null && (
                  <div
                    className={`gstCertificate-fileLoading ${uploadedFileError ? 'red' : 'green'}`}
                  />
                )}
                {uploadedFileError && (
                  <div className='gstCertificate-fileError'>{uploadedFileError}</div>
                )}
              </>
            ) : (
              <>
                <div
                  className={`gstCertificate-fileLoading ${uploadedFileError ? 'red' : 'green'}`}
                />
                {uploadedFileError && (
                  <div className='gstCertificate-fileError'>{uploadedFileError}</div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <div className='gstCertificate-inputHeader'>
        {`${b2bSignInState?.businessType === 'pan_card' ? 'Pan Card' : 'GSTIN Certificate'}`}*
      </div>
      <div className='gstCertificate-input'>
        <input
          type='file'
          ref={fileInputRef}
          accept='.png, .pdf, .jpeg, .jpg'
          onChange={(event) => fileUploadedHandler(event)}
        />
        <img
          src={gstCertifcateImg}
          alt='gstCertificate'
          tabIndex={0}
          role='button'
          onClick={() => fileUploadingHandler()}
          onDragOver={(event) => fileDragOverHandler(event)}
          onDrop={(event) => fileOnDropHandler(event)}
        />
      </div>
      <div className='gstCertificate-btn'>
        <button
          className='gstCertificate-submit whiteCta'
          disabled={
            !uploadedFile ||
            uploadedFileError ||
            (b2bSignInState?.businessType === 'pan_card' && !isPanValidationCheck)
          }
          tabIndex={0}
          type='button'
          onClick={() => onSubmitHandler()}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
}

export default GSTCertificate;
