import { client } from 'Gql';
import { TINY_URL } from 'Gql/Global';

export const tinyUrl = async (url) => {
  try {
    const res = await client.query({
      query: TINY_URL,
      variables: {
        url: encodeURIComponent(url),
      },
    });
    return res.loading ? null : res.error || res.data;
  } catch (err) {
    console.log(err);
  }
};
