import { IsB2BLogged } from 'B2B/Utils/IsB2B';

export const pushToDataLayer = (event) => {
  if (window.dataLayer) {
    const enhancedEvent = {
      ...event,
      ecommerce: {
        ...event.ecommerce,
        user_type: IsB2BLogged() ? 'B2B' : 'B2C',
      },
    };
    window.dataLayer.push(enhancedEvent);
  } else {
    console.warn('window.dataLayer is not defined');
  }
};
